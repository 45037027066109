export const AUTH_TOKEN = 'AUTH_TOKEN';
export const AUTH_SECRET = 'AUTH_SECRET';
export const USER_STATE = 'USER_STATE';
export const USER_QUERY = 'USER_QUERY';
export const STUDENTS_PROFILES_QUERY = 'STUDENTS_PROFILES_QUERY';
export const STUDENT_PROFILE_DATA_QUERY = 'STUDENT_PROFILE_DATA_QUERY';
export const ALL_CLASSES_OF_ALL_STUDENTS_QUERY = 'ALL_CLASSES_OF_ALL_STUDENTS_QUERY';
export const STUDENTS_CLASSES_QUERY = 'STUDENTS_CLASSES_QUERY';
export const STUDENT_CLASS_DETAILS_QUERY = 'STUDENT_CLASS_DETAILS_QUERY';
export const STUDENT_FITNESS_TESTS_LIST_QUERY = 'STUDENT_FITNESS_TESTS_LIST_QUERY';
export const BLOGS_LIST_QUERY = 'BLOGS_LIST_QUERY';
export const BLOGS_POST_QUERY = 'BLOGS_POST_QUERY';
export const ACADEMY_PROFILE_LIST_QUERY = 'ACADEMY_PROFILE_LIST_QUERY';
export const ACADEMY_PROFILE_QUERY = 'ACADEMY_PROFILE_QUERY';
export const EXISTING_DEMO_BOOKING_FOR_ACADEMY_QUERY = 'EXISTING_DEMO_BOOKING_FOR_ACADEMY_QUERY';
export const CLASSES_FEE_TRANSACTIONS_QUERY = 'CLASSES_FEE_TRANSACTIONS_QUERY';
export const ACADEMY_FEATURED_QUERY = 'ACADEMY_FEATURED_QUERY';
export const ACTIVE_STUDENT_STORAGE_KEY = 'ACTIVE_STUDENT_STORAGE_KEY';
export const ACTIVE_CLASS_STORAGE_KEY = 'ACTIVE_CLASS_STORAGE_KEY';
export const ACTIVE_CLASS_FEE_DATA_STORAGE_KEY = 'ACTIVE_CLASS_FEE_DATA_STORAGE_KEY';
export const NOTIFICATION_LIST_QUERY = 'NOTIFICATION_LIST_QUERY';
export const ALL_STUDENTS_DATA_STORAGE_KEY = 'ALL_STUDENTS_DATA_STORAGE_KEY';
export const ALL_CLASSES_DATA_STORAGE_KEY = 'ALL_CLASSES_DATA_STORAGE_KEY';
export const ATTENDANCE_OF_ACTIVE_STUDENT_FOR_CLASS = 'ATTENDANCE_OF_ACTIVE_STUDENT_FOR_CLASS';
export const CERTIFICATIONS_OF_ACTIVE_STUDENT_FOR_CLASS = 'CERTIFICATIONS_OF_ACTIVE_STUDENT_FOR_CLASS';
export const ASSIGNMENTS_OF_ACTIVE_STUDENT_FOR_CLASS = 'ASSIGNMENTS_OF_ACTIVE_STUDENT_FOR_CLASS';
export const REGULAR_ASSIGNMENTS_OF_STUDENT = 'REGULAR_ASSIGNMENTS_OF_STUDENT';
export const ASSIGNMENT_SUBMISSIONS_OF_STUDENT = 'ASSIGNMENT_SUBMISSIONS_OF_STUDENT';
export const ALL_STUDENT_BADGES_LIST = 'ALL_STUDENT_BADGES_LIST';
export const USER_ROLES_LIST = 'USER_ROLES_LIST';
export const ALL_USERS_LIST = 'ALL_USERS_LIST';
export const CLAIMABLE_STUDENTS_LIST = 'CLAIMABLE_STUDENTS_LIST';
export const CLAIMABLE_STUDENTS_LIST_FOR_ACADEMY = 'CLAIMABLE_STUDENTS_LIST_FOR_ACADEMY';
export const PARENT_DETAILS_QUERY = 'PARENT_DETAILS_QUERY';
export const ACADEMY_QUERY = 'ACADEMY_QUERY'
export const CLASS_QUERY = 'CLASS-QUERY'
export const ENROLMENT_QUERY = 'ENROLMENT_QUERY'
export const ENROLMENT_REQUESTS_LIST_QUERY = 'ENROLMENT_REQUESTS_LIST_QUERY'
export const SUBSCIBED_USER_QUERY = 'SUBSCIBED_USER_QUERY'
export const CLASS_TRAINING_FEE_STRUCTURE_QUERY = 'CLASS_TRAINING_FEE_STRUCTURE_QUERY'
export const DB_NAME = 'DB_NAME';
export const STORE_NAME = 'STORE_NAME';


