import { useQuery } from "react-query";
import { AcademyProfileService } from "../../services/academy-profile-service";
import { ACADEMY_PROFILE_LIST_QUERY } from "../../utils/constants/globals";
import { useNavigate } from "react-router-dom";
import { Routes } from '../navigation/routes';

const AcademyProfileListViewModel = () => {
    
    const navigate = useNavigate();


    const {
        isLoading: isAcademyProfileListFetching,
        data: academyProfiles,
    } = useQuery({
        queryKey: [ACADEMY_PROFILE_LIST_QUERY],
        queryFn: () => AcademyProfileService.instance.getAcademyProfileList(),
        refetchOnWindowFocus: false,
        enabled: true
    });

    const handleOpenAcademyProfile = (id: number) => {
        navigate(Routes.academy + `/${id}`, {replace: false});
    }

    return {
        isLoading: false,
        academyProfiles: academyProfiles?.data?.data || [],
        handleOpenAcademyProfile
    }
}

export default AcademyProfileListViewModel