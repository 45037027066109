import { useEffect, useState } from 'react';
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/bundle';
import { Navigation, FreeMode, Autoplay } from 'swiper/modules';
import SlideUtility from '../../../../utils/slider-utils';
import { AcademyData } from '../../../../models/academy/academy-data';

interface FeaturingAcademiesProps {
  academies: AcademyData[] | undefined
}

const AcademyCarousel = ({
  academies,
}: FeaturingAcademiesProps) => {
  const [data, setData] = useState<AcademyData[]>([]);
  const [swiperInstance, setSwiperInstance] = useState<any>(null);

  const sliderUtility = new SlideUtility(data);

  const breakpoints = {
    768: {
      slidesPerView: 3,
    },
    
    320: {
      slidesPerView: 1,
    },
  };

    const layout = (randomNumber:number) => {
      switch (randomNumber) {
      case 1:
        return 'top-[20vh] w-[90%] h-[40vh]';
      case 2:
            return 'w-[70%] h-[60vh]';
      case 3:
        return 'w-[70%] h-[60vh] left-[30%]'; 
      case 4:
        return 'w-full h-[40vh]';
      default:
        return 'w-full h-[40vh]'; 
    }
  }
  
  const innerLayout = (randomNumber:number) => {
    switch (randomNumber) {
      case 1:
        return 'sm:w-[50%] w-[90%] sm:h-[80%] h-[70%] sm:bottom-[54%] bottom-[70%] sm:right-[10%]  text-right';
      case 2:
        return 'sm:w-[70%] w-[100%] h-[60%]  sm:left-[30%] left-[40%]  text-right';
      case 3:
        return 'sm:w-[70%] w-full sm:h-[60%] h-[60%] sm:right-[75%] right-[45%]  text-left'; 
      case 4:
        return 'sm:w-[70%] w-[100%] h-[60%]  top-[70%] sm:right-[25%]';
      default:
        return 'sm:w-[70%] w-[100%] h-[60%]  top-[70%] sm:right-[25%]'; 
    }
  }
                        

  
  return (
    <div className='carousel flex flex-col items-center sm:gap-8 gap-4'>
      <div className='flex w-full'>
        
<Swiper modules={[Navigation, FreeMode, Autoplay]}
      // spaceBetween={15}
      slidesPerView={1}
      autoFocus={true}
      // breakpoints={breakpoints}
      freeMode={true}
      loop={true}
      className='w-[calc(100vw-5rem)] h-full p-6'
      onSlideChange={sliderUtility.handleSlideChange}
          onSwiper={setSwiperInstance}>
          
          {academies?.map((d: AcademyData, index: number) => {
            if (d && d.attributes && d.attributes.images && d.attributes?.images.data && d.attributes.images.data[0] && d.attributes.images.data[0].attributes) {
              const url = d.attributes.images.data[0].attributes.url;
              const generateNumber = index > 3 ? Math.floor(Math.random() * 4) + 1 : index;
              // const generateNumber=3
              const generatedClasses = layout(generateNumber);
              const generatedInnerClass = innerLayout(generateNumber);
              return (
                <SwiperSlide key={index}>
                  <div className={`relative bg-cover bg-center flex justify-end items-center ${generatedClasses}`} style={{ backgroundImage: `url(${url})`}}>
                    {/* Content Wrapper */}
                    <div className={`flex flex-col justify-between max-w-[600px] sm:p-8 p-4 bg-opacity-50 backdrop-blur-md relative bg-white ${generatedInnerClass}`}>
                      <h1 className="sm:text-3xl font-bold text-black">{ d?.attributes?.name}</h1>
                      <p className="sm:text-lg font-light text-black">
                       {d?.attributes?.disciplines}
                      </p>
                      <button className=" px-8 sm:py-3 sm:w-48 w-auto self-end bg-black text-white text-lg font-semibold rounded-md">
                        Explore
                      </button>
                    </div>
                  </div>
                </SwiperSlide>
              )
            }
          })}
       </Swiper> 
        </div>
      <div className="flex gap-60">
      <button 
        className={`shadow-sm bg-[#F6B40A] text-white w-[3rem] h-[3rem] text-2xl rounded-full flex items-center justify-center`} 
        onClick={() => {
          if (swiperInstance) {
            swiperInstance.slidePrev();
          }
        }}
      >
        {'<'}
      </button>
      <button 
        className={`shadow-sm bg-[#F6B40A] text-white w-[3rem] h-[3rem] text-2xl rounded-full flex items-center justify-center`} 
        onClick={() => {
          if (swiperInstance) {
            swiperInstance.slideNext();
          }
        }}
      >
        {'>'}
      </button>
    </div>
    </div>
  )
}

export default AcademyCarousel;
